
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Location } from '@/types/common.d'
import { HydrantDetail } from '@/types/hydrant.d'
import MapLocation from '@/components/mapLocation/Index.vue'

@Component({
  name: 'HydrantAdd',
  components: { MapLocation }
})
export default class extends Vue {
  private formData: HydrantDetail = {
    projectId: '',
    fightingName: '',
    fightingNum: '',
    longitude: '',
    latitude: ''
  }

  private location: Location[] | null = []
  private submitDisabled = false

  private rulesForm = {
    fightingName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
    fightingNum: [{ required: true, message: '请输入编号', trigger: 'blur' }],
    projectId: [{ required: true, message: '请选择项目', trigger: 'change' }]
  }

  get fightingId () {
    return this.$route.params.id || ''
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    if (this.fightingId) {
      // 编辑
      this.getDetail()
    }
  }

  // 经纬度
  locationChange (arr: Location[]) {
    this.formData.longitude = arr[0].longitude
    this.formData.latitude = arr[0].latitude
  }

  confirmAdd () {
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitDisabled = true
        const urls = this.fightingId ? this.$apis.hydrant.updateFireFighting : this.$apis.hydrant.insertFireFighting
        this.$axios.post(urls, this.formData).then((res: any) => {
          const text = this.fightingId ? '编辑成功' : '新增成功'
          this.$message.success(text)
          this.$router.push({ path: '/hydrantManage' })
        }).finally(() => {
          this.submitDisabled = false
        })
      }
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.hydrant.selectFireFightingByFightingId, {
      fightingId: this.fightingId
    }).then((res) => {
      this.location = res.longitude && res.latitude ? [{ longitude: res.longitude, latitude: res.latitude }] : []
      console.log('location:', this.location)
      this.formData = res
    })
  }
}
